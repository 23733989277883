<template>
  <div>
    <z-card title="Assinatura" width="100%" :opened="opened" persistent>
      <template #content>
        <div class="d-flex flex-column align-center mt-4">
          <p class="body-text mb-4">
            {{ $t("reservation.confirmation_conclusion") }}
          </p>
          <p class="body-text mb-4">
            {{ $t("reservation.signing_easier") }}
          </p>
          <vue-signature-pad
            ref="signaturePad"
            width="100%"
            height="200px"
            :custom-style="{ 'border-bottom': '1px solid black' }"
          />
          <z-btn
            class="my-6"
            width="100%"
            :text="$t('reservation.clean_signature')"
            icon="$close_rounded"
            @click="clean"
          />
          <z-btn
            width="100%"
            primary
            :text="$t('reservation.advance_button')"
            :is-loading="isLoading"
            @click="save"
          />
          <div class="my-6" style="border-top: 1px solid #ddd; width: 100%" />
          <z-btn
            width="100%"
            :disabled="isLoading"
            :text="$t('reservation.back_button')"
            @click="$store.dispatch('flow/guest/previous')"
          />
        </div>
        <finalized-fnrh-modal
          :opened="isFinalizedFnrhModalOpen"
          :is-loading="isLoadingNext"
          @click:ok="onClickOk"
        />
      </template>
    </z-card>
  </div>
</template>

<script>
import { onMounted, ref } from "vue"
import { useNamespacedMutations } from "vuex-composition-helpers/dist"

import { useStoreAction } from "@/composables"
import { useTracking } from "@/composables/useTracking"

import FinalizedFnrhModal from "./components/FinalizedFnrhModal.vue"

export default {
  components: { FinalizedFnrhModal },
  beforeRouteEnter(_, __, next) {
    // Se for desktop, vai ser aberto como modal pelo layout
    if (window.screen.width >= 768) {
      return false
    } else {
      next()
    }
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const signaturePad = ref(null)
    const isFinalizedFnrhModalOpen = ref(false)
    const { changeSignature } = useNamespacedMutations("guest", [
      "changeSignature",
    ])
    const { sendSignature, isLoading } = useStoreAction(
      "guest",
      "sendSignature"
    )
    const { next, isLoading: isLoadingNext } = useStoreAction(
      "flow/guest",
      "next"
    )
    const { trackEvent } = useTracking()

    const clean = () => {
      signaturePad.value.clearSignature()
    }

    const save = async () => {
      const { isEmpty, data } = signaturePad.value.saveSignature()

      if (isEmpty === false) {
        changeSignature(data)
        await sendSignature()
        isFinalizedFnrhModalOpen.value = true
      }
    }

    const onClickOk = async () => {
      await next()
    }

    onMounted(() => trackEvent("step_signature"))

    return {
      isLoading,
      isLoadingNext,
      signaturePad,
      clean,
      save,
      isFinalizedFnrhModalOpen,
      onClickOk,
    }
  },
}
</script>
